import React, { useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import {
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { useRowSelect } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { contractFilesSelector } from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { useDispatch, useSelector } from 'store/utils';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { businessCustomerContractTabsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  deleteContractFiles,
  getContractFiles,
  updateOrCreateContractFile,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { ContractFile } from 'dto/businessCustomerContracts';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { getBaseDataFile } from 'features/businessCustomerContracts/businessCustomerContractService';

export const FilesTable = () => {
  const files = useSelector(contractFilesSelector);
  const fileTypes = useClassificationOptions(ClassificationGroup.FILE_TYPE);
  const loading = useSelector(businessCustomerContractTabsLoadingSelector);
  const dispatch = useDispatch();
  const data = useMemo(() => files ?? [], [files]);
  const alert = useAlert();

  useEffect(() => {
    dispatch(getContractFiles());
  }, [dispatch]);

  const columns = useMemo<TableColumns<ContractFile>>(
    () => [
      {
        id: 'fileType.id',
        accessor: ({ fileType }) => fileType?.id,
        Header: <TransTableHead i18nKey="fileType" />,
        editableProps: {
          required: true,
          options: fileTypes,
        },
        type: 'select',
      },
      {
        id: 'comment',
        accessor: 'comment',
        editableProps: {
          required: false,
        },
        Header: <TransTableHead i18nKey="comment" />,
      },
      {
        id: 'file',
        accessor: ({ file }) => file?.name ?? '',
        Header: <TransTableHead i18nKey="file" />,
        editableProps: {
          multiple: false,
          required: true,
        },
        type: 'file',
        wrapper: (field, cell) => {
          if (Boolean(cell.row.state.editable)) {
            return field;
          }

          const fileId = cell.row.original.file?.guid;
          const fileName = cell.row.original.file?.name;

          return (
            <Button
              sx={{ paddingLeft: 0 }}
              variant="text"
              onClick={() => getBaseDataFile({ fileId, fileName })}
            >
              {cell.row.original.file?.name}
            </Button>
          );
        },
      },
    ],
    [fileTypes]
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
      onRowUpdate: async ({ ...contractFile }) => {
        const formData = new FormData();
        formData.append('File', contractFile.file as unknown as Blob);
        formData.append('FileTypeId', contractFile.fileType.id);
        formData.append('Comment', contractFile.comment);

        await dispatch(
          updateOrCreateContractFile({
            payload: formData,
            id: contractFile.id,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(getContractFiles());
            alert.success(
              <TransAlert
                i18nKey={
                  contractFile.id
                    ? 'contractFileUpdated'
                    : 'contractFileCreated'
                }
              />
            );
          });
      },
    },
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteContractFiles(table.selectedFlatRows.map((row) => row.original.id))
    )
      .unwrap()
      .then(() => {
        dispatch(getContractFiles());
        alert.success(<TransAlert i18nKey="contractFileRemoved" />);
      });
  }, [alert, dispatch, table.selectedFlatRows]);

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider form={form}>
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                variant="text"
                onClick={removeSelectedRows}
                startIcon={<Icon name="delete" />}
                disabled={!table.selectedFlatRows.length}
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
