import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { useDispatch, useSelector } from 'store/utils';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { businessCustomerAddressesSelector } from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomerTabsLoadingSelector } from 'features/loading/loadingSelectors';
import { ClassificationGroup } from 'dto/classification';
import { useAlert } from 'react-alert';
import {
  deleteBusinessCustomerAddresses,
  getBusinessCustomerAddresses,
  updateOrCreateBusinessCustomerAddress,
} from 'features/businessCustomer/businessCustomerActions';
import {
  Button,
  FormProvider,
  Icon,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { BusinessCustomerAddress } from 'dto/businessCustomer';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { useRowSelect } from 'react-table';
import { Box, Stack } from '@mui/material';
import { TransButton } from 'i18n/trans/button';

export const Addresses: FC = () => {
  const addresses = useSelector(businessCustomerAddressesSelector);
  const data = useMemo(() => addresses ?? [], [addresses]);
  const loading = useSelector(businessCustomerTabsLoadingSelector);
  const cities = useClassificationOptions(ClassificationGroup.CITY);
  const countries = useClassificationOptions(ClassificationGroup.COUNTRY);
  const counties = useClassificationOptions(ClassificationGroup.COUNTY);
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessCustomerAddresses());
  }, [dispatch]);

  const columns = useMemo<TableColumns<BusinessCustomerAddress>>(
    () => [
      {
        id: 'streetAddress',
        accessor: 'streetAddress',
        Header: <TransTableHead i18nKey="street" />,
        editableProps: {
          required: true,
        },
      },
      {
        accessor: 'zipCode',
        Header: <TransTableHead i18nKey="zipCode" />,
        editableProps: {
          required: false,
        },
      },
      {
        id: 'country.id',
        accessor: ({ country }) => country?.id,
        Header: <TransTableHead i18nKey="country" />,
        type: 'select',
        editableProps: {
          options: countries,
          showEmptyOption: true,
          required: true,
        },
      },
      {
        id: 'county.id',
        accessor: ({ county }) => county?.id,
        Header: <TransTableHead i18nKey="county" />,
        type: 'select',
        editableProps: {
          options: counties,
          showEmptyOption: true,
          required: false,
        },
      },
      {
        id: 'city.id',
        accessor: ({ city }) => city?.id,
        Header: <TransTableHead i18nKey="city" />,
        type: 'select',
        editableProps: {
          options: cities,
          showEmptyOption: true,
          required: false,
        },
      },
      {
        id: 'isActive',
        accessor: ({ isActive }) => (
          <Icon
            name={isActive ? 'check' : 'close'}
            color={isActive ? 'success' : 'error'}
          />
        ),
        Header: <TransTableHead i18nKey="active" />,
        editableProps: {
          required: false,
        },
        type: 'checkbox',
      },
    ],
    [cities, counties, countries]
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
      onRowUpdate: async ({ city, country, county, ...rest }) => {
        const payload = {
          ...rest,
          cityId: city?.id,
          countryId: country?.id,
          countyId: county?.id,
        };

        await dispatch(updateOrCreateBusinessCustomerAddress(payload)).unwrap();
        dispatch(getBusinessCustomerAddresses());
        alert.success(
          <TransAlert
            i18nKey={
              payload.id
                ? 'businessCustomerAddressUpdated'
                : 'businessCustomerAddressCreated'
            }
          />
        );
      },
    },
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteBusinessCustomerAddresses(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    )
      .unwrap()
      .then(() => {
        dispatch(getBusinessCustomerAddresses());
        alert.success(<TransAlert i18nKey="businessCustomerAddressRemoved" />);
      });
  }, [alert, dispatch, table.selectedFlatRows]);

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider form={form}>
        <Box sx={{ mb: 6 }}>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                variant="text"
                onClick={removeSelectedRows}
                startIcon={<Icon name="delete" />}
                disabled={!table.selectedFlatRows.length}
                color="error"
              >
                <TransButton i18nKey="deleteSelected" />
              </Button>
              <Button
                variant="text"
                onClick={addRow}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="addNew" />
              </Button>
            </Stack>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
