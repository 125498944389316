import {
  Button,
  DateField,
  FormControl,
  FormProvider,
  formSubmit,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useCallback, useEffect, useMemo } from 'react';
import { VoucherSearchFilter } from 'dto/customerProfile';
import {
  getVouchers,
  resetVoucherSearchTable,
  setVouchersFilter,
} from 'features/customerProfile/customerProfileActions';
import { useDispatch, useSelector } from 'store/utils';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { vouchersFilterSelector } from 'features/customerProfile/customerProfileSelectors';

export const ConnectVouchersSearchForm = () => {
  const dispatch = useDispatch();
  const businessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(vouchersFilterSelector);

  useComponentWillUnmount(() => {
    dispatch(setVouchersFilter({}));
    dispatch(resetVoucherSearchTable());
  });

  const onSubmit = useCallback(
    (
      values: VoucherSearchFilter & {
        ValidityFromTo?: { from: Date | null; to: Date | null };
      }
    ) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();

        if (values.ValidityFromTo) {
          values.validFrom = values.ValidityFromTo.from;
          values.validTo = values.ValidityFromTo.to;
        }

        await dispatch(getVouchers({ ...values, offset: 0 }));
      }),
    [dispatch]
  );

  const initialValues = useMemo(
    () => ({ ownerId: businessEntityId, ...filter }),
    [businessEntityId, filter]
  );

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setVouchersFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm<VoucherSearchFilter>({
    onSubmit,
    initialValues,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setVouchersFilter({}));
  }, [form, dispatch]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={2}>
          <Grid item xs={1}>
            <TextField
              name="Code"
              label={<TransField i18nKey="voucherCode" />}
            />
          </Grid>
          <Grid item xs={1}>
            <DateField
              name="ValidityFromTo"
              label={<TransField i18nKey="validFromTo" />}
              selectsRange
            />
          </Grid>
          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" spacing={2}>
              <FormControl label="&nbsp;">
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="text"
                  onClick={handleReset}
                >
                  <TransButton i18nKey="resetFields" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button variant="contained" type="submit" icon="search">
                  <TransButton i18nKey="search" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
