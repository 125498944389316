import { ChangeEvent, FC, useCallback, useMemo } from 'react';
import {
  Checkbox,
  ExternalLink,
  Icon,
  Loadable,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { CustomerProfileTravelPasses, TravelPass } from 'dto/customerProfile';
import { TransTableHead } from 'i18n/trans/table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { getTravelPasses } from 'features/customerProfile/customerProfileActions';
import {
  Row,
  useFilters,
  useMountedLayoutEffect,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import {
  travelPassesSelector,
  travelPassFilterSelector,
} from 'features/customerProfile/customerProfileSelectors';
import { travelPassSearchTableLoadingSelector } from 'features/loading/loadingSelectors';
import { Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import Divider from '@mui/material/Divider';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';

interface ConnectTravelPassTableProps {
  onSelectedRowsChange: (rows: Row<TravelPass>[]) => void;
}

export const ConnectTravelPassTable: FC<ConnectTravelPassTableProps> = ({
  onSelectedRowsChange,
}) => {
  const travelPasses = useSelector(travelPassesSelector);
  const filter = useSelector(travelPassFilterSelector);
  const loading = useSelector(travelPassSearchTableLoadingSelector);
  const dispatch = useDispatch();

  const getPage = useCallback(
    (pageSize: number) => {
      if (travelPasses) {
        const { limit = pageSize, offset } = travelPasses;
        return offset / limit;
      }
      return 0;
    },
    [travelPasses]
  );

  const data = useMemo(() => travelPasses?.items ?? [], [travelPasses]);

  const columns = useMemo<TableColumns<TravelPass>>(
    () => [
      {
        id: 'number',
        accessor: 'number',
        Header: <TransTableHead i18nKey="travelPassNumber" />,
        Cell: ({ row }: { row: Row<CustomerProfileTravelPasses> }) => (
          <ExternalLink
            path={`/Trips/TravelPass/Edit/${row.original.id}`}
            underline="none"
            content={row.original.number}
          />
        ),
      },
      {
        id: 'type.id',
        accessor: ({ type }) => type?.name,
        Header: <TransTableHead i18nKey="travelPassType" />,
      },
      {
        id: 'purchasedOn.value',
        accessor: ({ purchasedOn }) =>
          formatDate(purchasedOn?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="purchasedOn" />,
      },
      {
        id: 'validUntil.value',
        accessor: ({ validUntil }) =>
          formatDate(validUntil?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="validUntil" />,
      },
      {
        id: 'isActivated',
        accessor: 'isActivated',
        Header: <TransTableHead i18nKey="activated" />,
        Cell: ({
          row: {
            original: { isActivated },
          },
        }) => isActivated && <Icon name="check" color="success" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getTravelPasses({ ...filter, ...paginationParams })),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: travelPasses?.totalCount,
    },
    useFilters,
    useIndeterminateRowSelectCheckbox,
    usePagination,
    useRowSelect
  );

  useMountedLayoutEffect(() => {
    onSelectedRowsChange && onSelectedRowsChange(table.selectedFlatRows);
  }, [onSelectedRowsChange, table.selectedFlatRows]);

  useComponentWillUnmount(() => {
    onSelectedRowsChange([]);
  });

  const handleIsActiveFilterToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await dispatch(getTravelPasses({ ...filter, isValid: e.target.checked }));
    },
    [dispatch, filter]
  );

  return (
    <Loadable loading={loading}>
      {Boolean(travelPasses?.items.length) && (
        <>
          <Divider sx={{ mt: 3 }} />
          <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 2 }}>
            <Typography variant="subtitle" fontWeight="700" sx={{ mr: 2 }}>
              <TransSubtitle i18nKey="searchResults" />
            </Typography>
            <div>
              <Checkbox
                onChange={handleIsActiveFilterToggle}
                label={
                  <Typography
                    variant="body2"
                    color="text.primary"
                    component="span"
                  >
                    <TransField i18nKey="showValidTravelPasses" />
                  </Typography>
                }
                size="small"
              />
            </div>
          </Stack>
          <Table table={table} />
        </>
      )}
    </Loadable>
  );
};
