import React, { FC, useEffect } from 'react';
import { CardHeader } from '@fleet/shared/mui';
import { Layout, Loadable } from '@fleet/shared';
import { BusinessCustomerContractForm } from './BusinessCustomerContractForm';
import { NavLink, Route, Switch, useParams } from 'react-router-dom';
import { EmployeeAccountsDrawerForm } from './tabs/EmployeeAccounts/EmployeeAccountsDrawerForm';
import { Tab } from './tabs/Tab';
import { TransHeader } from 'i18n/trans/header';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerContractEditViewLoadingSelector } from 'features/loading/loadingSelectors';
import { currentBusinessCustomerContractSelector } from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import {
  getBusinessCustomerContractById,
  resetCurrentBusinessCustomerContract,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(
  (theme) => ({
    navLink: {
      color: theme.palette.text.warning,
      marginLeft: '5px',
    },
  }),
  {
    name: 'BusinessCustomerContractForm',
  }
);

export const BusinessCustomerContractsDetails: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const loading = useSelector(businessCustomerContractEditViewLoadingSelector);
  const businessCustomerContract = useSelector(
    currentBusinessCustomerContractSelector
  );
  const { businessCustomerId, contractId } =
    useParams<{ businessCustomerId: string; contractId: string }>();

  useEffect(() => {
    if (businessCustomerId && contractId) {
      dispatch(
        getBusinessCustomerContractById({
          businessCustomerId: +businessCustomerId,
          contractId: +contractId,
        })
      );
    }

    return () => {
      dispatch(resetCurrentBusinessCustomerContract());
    };
  }, [dispatch, businessCustomerId, contractId]);

  return (
    <Loadable loading={loading}>
      <div>
        <Layout
          header={
            <CardHeader
              title={
                businessCustomerContract ? (
                  <>
                    <TransHeader
                      i18nKey="businessCustomerContractEdit"
                      values={{
                        number: businessCustomerContract.contractNumber,
                      }}
                    />
                    <NavLink
                      to={`/customers/business-customers/edit/${businessCustomerContract.businessCustomer.id}`}
                      className={classes.navLink}
                    >
                      {businessCustomerContract.businessCustomer.name}
                    </NavLink>
                  </>
                ) : (
                  <TransHeader i18nKey="businessCustomerContractNew" />
                )
              }
            />
          }
        >
          <BusinessCustomerContractForm />
        </Layout>
      </div>
      <Switch>
        <Route
          path="/customers/business-customer-contracts/edit/:businessCustomerId/:contractId/employee_accounts/:action(create|edit)?/:entityId?"
          component={EmployeeAccountsDrawerForm}
          exact
        />
      </Switch>
      {businessCustomerContract && <Tab />}
    </Loadable>
  );
};
