import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  Icon,
  Loadable,
  Modal,
  SearchResult,
  Table,
  TableColumns,
  TextField,
  useForm,
} from '@fleet/shared';
import { Row, useRowSelect, useTable } from 'react-table';
import {
  CardContent,
  Divider,
  Grid,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { findCustomerProfilesLoadingSelector } from 'features/loading/loadingSelectors';
import { customerProfileLightListSelector } from 'features/customerProfile/customerProfileSelectors';
import { currentBusinessCustomerContractSelector } from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import {
  CustomerProfile,
  CustomerProfileSearchFilter,
} from 'dto/customerProfile';
import {
  getCustomerProfileListLight,
  setCustomerProfileFilter,
} from 'features/customerProfile/customerProfileActions';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';

interface CustomerProfileChoice {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneAreaCode: string;
}

interface FindCustomerProfilesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (customerProfile: CustomerProfileChoice) => void;
}

export const FindCustomerProfilesModal: FC<FindCustomerProfilesModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [selectedCustomerProfile, setCustomerProfile] = useState<
    CustomerProfileChoice | undefined
  >();
  const dispatch = useDispatch();
  const customers = useSelector(customerProfileLightListSelector);
  const loading = useSelector(findCustomerProfilesLoadingSelector);
  const contract = useSelector(currentBusinessCustomerContractSelector);
  const data = useMemo(() => customers ?? [], [customers]);

  const onSearchSubmit = (formFilter: CustomerProfileSearchFilter) =>
    formSubmit(async () => {
      (document.activeElement as HTMLInputElement)?.blur?.();
      await dispatch(
        getCustomerProfileListLight({
          organizationId: contract?.contractor.id,
          ...formFilter,
        })
      ).unwrap();
    });

  const { form, submitting, handleSubmit } = useForm<CustomerProfile>({
    onSubmit: onSearchSubmit,
    subscription: { submitting: true },
  });

  const columns = useMemo<TableColumns<CustomerProfile>>(
    () => [
      {
        id: 'selection',
        width: 40,
        Cell: ({ row }: { row: Row<CustomerProfile> }) => (
          <Radio
            onClick={() =>
              setCustomerProfile({
                email: row.original.email,
                firstName: row.original.firstName,
                lastName: row.original.lastName,
                phoneNumber: row.original.phoneNumber,
                phoneAreaCode: row.original.phoneAreaCode,
                id: row.original.id,
              })
            }
            size="small"
            checked={row.original.id === selectedCustomerProfile?.id}
          />
        ),
      },
      {
        accessor: 'firstName',
        Header: <TransTableHead i18nKey="firstName" />,
      },
      {
        accessor: 'lastName',
        Header: <TransTableHead i18nKey="lastName" />,
      },
      {
        accessor: 'email',
        Header: <TransTableHead i18nKey="email" />,
      },
    ],
    [selectedCustomerProfile?.id]
  );

  const table = useTable(
    {
      data,
      columns,
    },
    useRowSelect
  );

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  const resetModal = useCallback(() => {
    dispatch(setCustomerProfileFilter({}));
    handleReset();
    setCustomerProfile(undefined);
  }, [dispatch, handleReset]);

  return (
    <Modal
      onClose={() => {
        resetModal();
        onClose();
      }}
      title={<TransTitle i18nKey="searchCustomerProfiles" />}
      actionButton={
        <Button
          startIcon={<Icon name="check" size={16} />}
          variant="contained"
          type="submit"
          onClick={() => {
            onSubmit(selectedCustomerProfile!);
            resetModal();
            onClose();
          }}
          disabled={submitting || !selectedCustomerProfile}
        >
          <TransButton i18nKey="confirm" />
        </Button>
      }
      open={isOpen}
    >
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={2}>
            <Grid item xs={1}>
              <TextField name="name" label={<TransField i18nKey="name" />} />
            </Grid>
            <Grid item xs={1}>
              <TextField name="email" label={<TransField i18nKey="email" />} />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFields" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <Divider sx={{ marginTop: '24px', marginBottom: '16px' }} />
      <Loadable loading={loading}>
        <SearchResult results={data.length} loading={loading}>
          <Table
            caption={
              <>
                <CardContent sx={{ padding: '16px 24px' }}>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="subtitle" fontWeight="700">
                      <TransSubtitle i18nKey="searchResults" />
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ml: 2 }}
                    >
                      <TransSubtitle
                        i18nKey="customerProfilesQty"
                        values={{ num: data.length }}
                      />
                    </Typography>
                  </Stack>
                </CardContent>
              </>
            }
            table={table}
          />
        </SearchResult>
      </Loadable>
    </Modal>
  );
};
