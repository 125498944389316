import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'store/utils';
import {
  deleteTransactionConversionRates,
  getDistanceConversionRates,
  getPlaceClasses,
  getTransactionConversionRates,
  updateOrCreateTransactionConversionRate,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import {
  placeClassesSelector,
  transactionConversionRatesSelector,
} from 'features/loyaltyProgram/loyaltyProgramSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  FormProvider,
  Loadable,
  makeClassificationOptions,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { TransactionConversionRate } from 'dto/loyaltyProgram';
import { TransTableHead } from 'i18n/trans/table';
import { useFilters, useRowSelect } from 'react-table';
import { Box, Button, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { transactionConversionRatesLoadingSelector } from 'features/loading/loadingSelectors';

export const TransactionConversionRates: FC = () => {
  const dispatch = useDispatch();
  const placeClasses = useSelector(placeClassesSelector);
  const data = useSelector(transactionConversionRatesSelector);
  const loading = useSelector(transactionConversionRatesLoadingSelector);
  const currencies = useClassificationOptions(ClassificationGroup.CURRENCY);
  const transactions = useClassificationOptions(
    ClassificationGroup.TRANSACTION_TYPE
  );

  useEffect(() => {
    dispatch(getPlaceClasses());
    dispatch(getTransactionConversionRates());
  }, [dispatch]);

  const { form, values: formValues } = useForm<{
    rows: Array<TransactionConversionRate>;
  }>({
    initialValues: {
      rows: data,
    },
    subscription: { values: true },
  });

  const columns: TableColumns<TransactionConversionRate> = useMemo(
    () => [
      {
        id: 'currency.id',
        accessor: ({ currency }) => currency?.id,
        Header: <TransTableHead i18nKey="currency" />,
        type: 'select',
        editableProps: {
          options: currencies,
          showEmptyOption: true,
        },
      },
      {
        accessor: 'rate',
        Header: <TransTableHead i18nKey="rate" />,
        type: 'text',
        editableProps: { required: false, type: 'number' },
        wrapper: (field, cell) => {
          const currentRow = cell.row.index;
          const currency = currencies.find(
            (currency) =>
              currency.value === formValues.rows[currentRow]?.currency?.id
          );

          if (Boolean(cell.row.state.editable)) {
            return (
              <div style={{ display: 'flex' }}>
                <div style={{ margin: 'auto 0', width: 65 }}>
                  1 {currency?.label} =
                </div>
                {field}
              </div>
            );
          }

          return (
            <div style={{ display: 'flex' }}>
              <div style={{ margin: 'auto 0', width: 45 }}>
                1 {currency?.label} =
              </div>
              {`${field} points`}
            </div>
          );
        },
      },
      {
        id: 'transactionType.id',
        accessor: ({ transactionType }) => transactionType?.id,
        Header: <TransTableHead i18nKey="transactionType" />,
        type: 'select',
        editableProps: {
          options: transactions,
          showEmptyOption: true,
        },
      },
      {
        id: 'placeClass.id',
        accessor: ({ placeClass }) => placeClass?.id,
        Header: <TransTableHead i18nKey="placeClasses" />,
        type: 'select',
        editableProps: {
          options: makeClassificationOptions(placeClasses),
          showEmptyOption: true,
          required: false,
        },
      },
    ],
    [placeClasses, formValues, currencies, transactions]
  );

  const table = useFormTable(
    {
      data,
      columns,
      form,
      onRowUpdate: async (payload) => {
        const { currency, placeClass, transactionType, ...rest } = payload;

        await dispatch(
          updateOrCreateTransactionConversionRate({
            ...rest,
            currencyId: currency.id,
            transactionTypeId: transactionType.id,
            placeClassId: placeClass?.id,
          })
        );
        await dispatch(getTransactionConversionRates());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<TransactionConversionRate>) => {
      await dispatch(deleteTransactionConversionRates(payload));
      await dispatch(getDistanceConversionRates());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider form={form}>
        <Box sx={{ mb: 6 }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Button
              variant="text"
              onClick={removeSelectedRows}
              startIcon={<Icon name="delete" />}
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button
              variant="text"
              onClick={addRow}
              startIcon={<Icon name="plus" />}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
    </Loadable>
  );
};
