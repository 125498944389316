import { ChangeEvent, FC, useCallback, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, useRowSelect } from 'react-table';
import {
  Checkbox,
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  tableRowClickEventWrapper,
  useForm,
  useFormTable,
  useFormTableControls,
} from '@fleet/shared';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { useDispatch, useSelector } from 'store/utils';
import {
  currentBusinessCustomerSelector,
  businessCustomerUnitFilterSelector,
  businessCustomerUnitsSelector,
} from 'features/businessCustomer/businessCustomerSelectors';
import { businessCustomerTabsLoadingSelector } from 'features/loading/loadingSelectors';
import { getBusinessCustomerUnits } from 'features/businessCustomer/businessCustomerActions';
import { BusinessCustomerUnit } from 'dto/businessCustomer';
import { TransTableHead } from 'i18n/trans/table';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';

export const Units: FC = () => {
  const units = useSelector(businessCustomerUnitsSelector);
  const data = useMemo(() => units?.items ?? [], [units]);
  const loading = useSelector(businessCustomerTabsLoadingSelector);
  const filter = useSelector(businessCustomerUnitFilterSelector);
  const businessCustomer = useSelector(currentBusinessCustomerSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getBusinessCustomerUnits({ isActive: true }));
  }, [dispatch]);

  const link = useCallback(
    (row: Row<BusinessCustomerUnit>) =>
      `/customers/business-customers/edit/${businessCustomer?.id}/units/edit/${row.original.id}`,
    [businessCustomer?.id]
  );

  const columns = useMemo<TableColumns<BusinessCustomerUnit>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => (
          <Link to={link(row)} onClick={tableRowClickEventWrapper}>
            {value}
          </Link>
        ),
      },
      {
        id: 'address',
        accessor: ({ address }) => address.streetAddress,
        Header: <TransTableHead i18nKey="address" />,
      },
      {
        id: 'isActive',
        accessor: ({ isActive }) => (
          <Icon
            name={isActive ? 'check' : 'close'}
            color={isActive ? 'success' : 'error'}
          />
        ),
        Header: <TransTableHead i18nKey="active" />,
        editableProps: {
          required: false,
        },
        type: 'checkbox',
      },
    ],
    [link]
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
    },
    useRowSelect
  );

  useFormTableControls({
    table,
    form,
  });

  const handleIsActiveFilterToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await dispatch(
        getBusinessCustomerUnits(
          e.target.checked ? { isActive: e.target.checked } : {}
        )
      );
    },
    [dispatch]
  );

  return (
    <>
      <Loadable loading={loading}>
        <FormProvider form={form}>
          <Box sx={{ mb: 6 }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
              <div>
                <Checkbox
                  checked={filter?.isActive}
                  onChange={handleIsActiveFilterToggle}
                  label={
                    <Typography
                      variant="body2"
                      color="text.primary"
                      component="span"
                    >
                      <TransField i18nKey="showActiveUnits" />
                    </Typography>
                  }
                  size="small"
                />
              </div>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ ml: 'auto' }}
              >
                <Button
                  variant="text"
                  onClick={() =>
                    history.replace(
                      `/customers/business-customers/edit/${businessCustomer?.id}/units/create`
                    )
                  }
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="addNew" />
                </Button>
              </Stack>
            </Stack>
            <Table
              getTableProps={{
                sx: {
                  tableLayout: 'fixed',
                },
              }}
              getRowProps={(_, { row }) => ({
                sx: { cursor: 'pointer' },
                onClick: () => history.push(link(row)),
              })}
              table={table}
            />
          </Box>
        </FormProvider>
      </Loadable>
    </>
  );
};
