import type { FC } from 'react';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import {
  Checkbox,
  ExternalLink,
  FormProvider,
  Loadable,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { useRowSelect } from 'react-table';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { BusinessContractDiscountModal } from './DiscountsModal/BusinessContractDiscountModal';
import { formatDate } from '@fleet/shared/utils/date';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerTravelPassDiscountsSelector } from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { businessCustomerContractTabsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  deleteBusinessCustomerTravelPassDiscounts,
  getBusinessCustomerTravelPassDiscounts,
  updateOrCreateBusinessCustomerTravelDiscount,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { BusinessCustomerContractTravelPassDiscount } from 'dto/businessCustomerContracts';
import { TransTableHead } from 'i18n/trans/table';
import { TransAlert } from 'i18n/trans/alert';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransButton } from 'i18n/trans/button';

interface TravelPassDiscountFormProps {}

export const TravelPassDiscountsTable: FC<TravelPassDiscountFormProps> = () => {
  const [isTravelPassDiscountsModalOpen, setIsTravelPassDiscountsModalOpen] =
    useState(false);
  const travelPassDiscounts = useSelector(
    businessCustomerTravelPassDiscountsSelector
  );
  const loading = useSelector(businessCustomerContractTabsLoadingSelector);
  const alert = useAlert();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBusinessCustomerTravelPassDiscounts());
  }, [dispatch]);

  const data = useMemo(() => travelPassDiscounts ?? [], [travelPassDiscounts]);
  const columns = useMemo<
    TableColumns<BusinessCustomerContractTravelPassDiscount>
  >(
    () => [
      {
        id: 'travelPassBonusScheme.name',
        accessor: ({ travelPassBonusScheme }) => travelPassBonusScheme?.name,
        Header: <TransTableHead i18nKey="travelPassDiscount" />,
        editableProps: {
          readOnly: true,
          endAdornment: <Icon name="search" />,
          required: true,
          onClick: () => setIsTravelPassDiscountsModalOpen(true),
        },
        wrapper: (field, cell) => {
          if (Boolean(cell.row.state.editable)) {
            return field;
          }
          return (
            <ExternalLink
              path={`/General/TravelPassBonusScheme/Edit/${cell.row.original.travelPassBonusScheme?.id}`}
              underline="none"
              content={cell.row.original.travelPassBonusScheme?.name}
            />
          );
        },
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) => formatDate(validity?.from),
        Header: <TransTableHead i18nKey="startDate" />,
        type: 'date',
        editableProps: {
          required: true,
        },
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) => formatDate(validity?.to),
        Header: <TransTableHead i18nKey="endDate" />,
        type: 'date',
        editableProps: {
          required: true,
        },
      },
    ],
    []
  );

  const { form } = useForm<{
    rows: Array<BusinessCustomerContractTravelPassDiscount>;
  }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      form,
      data,
      columns,
      onRowUpdate: async ({ travelPassBonusScheme, ...rest }) => {
        await dispatch(
          updateOrCreateBusinessCustomerTravelDiscount({
            ...rest,
            travelPassBonusSchemeId: travelPassBonusScheme.id,
          })
        )
          .unwrap()
          .then(() => {
            dispatch(getBusinessCustomerTravelPassDiscounts());
            alert.success(
              <TransAlert
                i18nKey={
                  rest.id
                    ? 'businessCustomerTravelPassDiscountUpdated'
                    : 'businessCustomerTravelPassDiscountCreated'
                }
              />
            );
          });
      },
    },
    useIndeterminateRowSelectCheckbox,
    useRowEditActions,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteBusinessCustomerTravelPassDiscounts(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    )
      .unwrap()
      .then(() => {
        dispatch(getBusinessCustomerTravelPassDiscounts());
        alert.success(
          <TransAlert i18nKey="businessCustomerTravelPassDiscountRemoved" />
        );
      });
  }, [alert, dispatch, table.selectedFlatRows]);

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  const handleIsActiveFilterToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await dispatch(getBusinessCustomerTravelPassDiscounts(!e.target.checked));
    },
    [dispatch]
  );

  const onSubmitCallback = useCallback(
    (travelPassDiscountRow) => {
      const currentRows = form.getState().values.rows;
      const editingRowId = table.rows.find((row) => row.state.editable)!.id;
      const rowIndex = table.rows.findIndex((row) => row.id === editingRowId);

      if (rowIndex !== -1) {
        const newRows = [...currentRows];
        newRows[rowIndex] = {
          ...newRows[rowIndex],
          travelPassBonusScheme: {
            id: travelPassDiscountRow.original.id,
            name: travelPassDiscountRow.original.name,
          },
        };

        form.change('rows', newRows);
      }
    },
    [form, table.rows]
  );

  return (
    <>
      <Typography
        variant="subtitle"
        fontWeight="700"
        paragraph
        sx={{ mt: 3, mb: 3 }}
      >
        <TransSubtitle i18nKey="travelPassDiscounts" />
      </Typography>
      <Loadable loading={loading}>
        <FormProvider form={form}>
          <Box sx={{ mb: 6 }}>
            <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
              <div>
                <Checkbox
                  onChange={handleIsActiveFilterToggle}
                  label={
                    <Typography
                      variant="body2"
                      color="text.primary"
                      component="span"
                    >
                      <TransField i18nKey="showActiveTravelPassDiscounts" />
                    </Typography>
                  }
                  size="small"
                />
              </div>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ ml: 'auto' }}
              >
                <Button
                  variant="text"
                  onClick={removeSelectedRows}
                  startIcon={<Icon name="delete" />}
                  disabled={!table.selectedFlatRows.length}
                >
                  <TransButton i18nKey="deleteSelected" />
                </Button>
                <Button
                  variant="text"
                  onClick={addRow}
                  startIcon={<Icon name="plus" />}
                >
                  <TransButton i18nKey="addNew" />
                </Button>
              </Stack>
            </Stack>
            <Table
              getTableProps={{
                sx: {
                  tableLayout: 'fixed',
                },
              }}
              table={table}
            />
          </Box>
        </FormProvider>
        <BusinessContractDiscountModal
          onSubmit={onSubmitCallback}
          isOpen={isTravelPassDiscountsModalOpen}
          onClose={() => setIsTravelPassDiscountsModalOpen(false)}
          discountType="travel"
        />
      </Loadable>
    </>
  );
};
