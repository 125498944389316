import { Layout } from '@fleet/shared';
import { LoyaltyConfigurations } from 'routes/loyaltyProgram/LoyaltyConfigurations';
import { LoyaltySearchForm } from 'routes/loyaltyProgram/LoyaltySearchForm';
import { useSelector } from 'store/utils';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';

export const LoyaltyProgram = () => {
  const currentBusinessEntityId = useSelector(currentBusinessEntityIdSelector);

  if (!currentBusinessEntityId) {
    return null;
  }

  return (
    <>
      <div>
        <Layout>
          <LoyaltySearchForm />
        </Layout>
      </div>
      <LoyaltyConfigurations />
    </>
  );
};
