import _sortBy from 'lodash/sortBy';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  Button,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  ExternalLink,
  FormProvider,
  formSubmit,
  Loadable,
  RadioGroup,
  SelectField,
  useForm,
} from '@fleet/shared';
import { DateField, TextField } from '@fleet/shared/form';
import { Link } from 'react-router-dom';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { Icon } from '@fleet/shared/mui';
import { CustomerProfileRequest } from 'dto/customerProfile';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import { formatDate } from '@fleet/shared/utils/date';
import { useDispatch, useSelector } from 'store/utils';
import { countriesSelector } from 'features/classification/classificationSelectors';
import { CustomerProfileDeleteModal } from 'routes/customerProfile/CustomerProfileDeleteModal';
import {
  createCustomerProfile,
  getCustomerProfile,
  sendPasswordReset,
  updateCustomerProfile,
} from 'features/customerProfile/customerProfileActions';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { useAuth } from 'react-oidc-context';
import { currentCustomerProfileSelector } from 'features/customerProfile/customerProfileSelectors';

const useStyles = makeStyles(() => ({
  deleteButton: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

interface CustomerProfileFormProps {}

export const CustomerProfileForm: FC<CustomerProfileFormProps> = () => {
  const customerProfile = useSelector(currentCustomerProfileSelector);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const auth = useAuth();
  const countryOptions = useClassificationOptions(ClassificationGroup.COUNTRY);
  const alert = useAlert();
  const dispatch = useDispatch();
  const languages = useClassificationOptions(ClassificationGroup.CULTURE);
  const sex = useClassificationOptions(ClassificationGroup.SEX);
  const seatPreferences = useClassificationOptions(
    ClassificationGroup.SEAT_PREFERENCE
  );
  const dietaryRequirements = useClassificationOptions(
    ClassificationGroup.DIETARY_REQUIREMENT
  );
  const concessionTypes = useClassificationOptions(
    ClassificationGroup.CONCESSION_TYPE
  );
  const classes = useStyles();
  const loading = false;
  const sortedLanguages = _sortBy(languages, 'label');
  const initialValues = useMemo(() => {
    if (!customerProfile) {
      return {};
    }

    const {
      gender,
      country,
      preferredLanguage,
      dietaryRequirements,
      seatPreferences,
      concessionDetails,
      ...rest
    } = customerProfile;

    return {
      ...rest,
      genderId: gender?.id,
      countryId: country?.id,
      userId: Number(auth.user?.profile.sub),
      preferredLanguageId: preferredLanguage?.id,
      dietaryRequirementIds: dietaryRequirements?.map((d) => d.id),
      seatPreferenceIds: seatPreferences?.map((d) => d.id),
      concessionDetails: concessionDetails && {
        concessionTypeId: concessionDetails?.concessionType?.id,
        concessionId: concessionDetails?.concessionId,
      },
    };
  }, [customerProfile, auth.user?.profile.sub]);
  const areaCodes = useSelector(countriesSelector).map((country) => ({
    value: country.phoneAreaCode,
    label: country.phoneAreaCode,
  }));
  const sortedAreaCodes = _sortBy(areaCodes, 'label');

  const onSubmit = useCallback(
    (values: CustomerProfileRequest) =>
      formSubmit(async () => {
        const data = await dispatch(
          (values.id ? updateCustomerProfile : createCustomerProfile)(values)
        ).unwrap();
        dispatch(getCustomerProfile(data?.id));
        alert.success(
          <TransAlert
            i18nKey={
              data?.id ? 'customerProfileUpdated' : 'customerProfileCreated'
            }
          />
        );
      }),
    [alert, dispatch]
  );

  const { form, handleSubmit, pristine, submitting } =
    useForm<CustomerProfileRequest>({
      initialValues,
      onSubmit,
      subscription: { submitting: true, pristine: true },
    });

  const { reset } = form;
  const handleReset = useCallback(() => {
    reset(initialValues);
  }, [initialValues, reset]);

  const onSendPasswordResetEmail = useCallback(async () => {
    if (customerProfile?.id) {
      await dispatch(sendPasswordReset(customerProfile?.id));
      alert.success(<TransAlert i18nKey="passwordResetLink" />);
    }
  }, [alert, customerProfile?.id, dispatch]);

  return (
    <>
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <CardContent sx={{ p: '12px 24px' }}>
            <Loadable portal loading={loading}>
              <Stack
                direction="row"
                alignItems="center"
                mb="16px"
                justifyContent="space-between"
              >
                <Typography
                  variant="subtitle"
                  fontWeight="700"
                  paragraph
                  mb="0"
                >
                  <TransSubtitle i18nKey="details" />
                </Typography>

                <div>
                  <Button
                    variant="text"
                    onClick={onSendPasswordResetEmail}
                    startIcon={<Icon name="mail" />}
                  >
                    <TransButton i18nKey="passwordReset" />
                  </Button>
                  <Button
                    color="error"
                    variant="text"
                    onClick={() => setIsDeleteModalOpen(true)}
                    className={classes.deleteButton}
                    startIcon={<Icon name="trash" />}
                  >
                    <TransButton i18nKey="delete" />
                  </Button>
                </div>
              </Stack>
              <Grid
                container
                sx={{ alignItems: 'baseline', mb: '16px' }}
                spacing={3}
                columns={5}
              >
                <Grid item xs={1}>
                  <TextField
                    required
                    name="firstName"
                    label={<TransField i18nKey="firstName" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="lastName"
                    required
                    label={<TransField i18nKey="lastName" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    required
                    name="email"
                    label={<TransField i18nKey="email" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Grid
                    container
                    sx={{ alignItems: 'flex-end' }}
                    spacing={3}
                    columns={5}
                  >
                    <Grid item xs={2}>
                      <SelectField
                        options={sortedAreaCodes}
                        name="phoneAreaCode"
                        label={<TransField i18nKey="phone" />}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField name="phoneNumber" label="" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <DateField
                    popperProps={{ strategy: 'fixed' }}
                    dateType="end"
                    name="birthDate"
                    label={<TransField i18nKey="birthdate" />}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ alignItems: 'baseline', mb: '16px' }}
                spacing={3}
                columns={5}
              >
                <Grid item xs={1}>
                  <SelectField
                    options={sortedLanguages}
                    name="preferredLanguageId"
                    label={<TransField i18nKey="preferredLanguage" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    options={countryOptions}
                    name="countryId"
                    label={<TransField i18nKey="country" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="city"
                    label={<TransField i18nKey="city" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="streetAddress"
                    label={<TransField i18nKey="streetHouseNumber" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="zipCode"
                    label={<TransField i18nKey="zipCode" />}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                sx={{ alignItems: 'baseline', pb: '36px' }}
                spacing={3}
                columns={5}
              >
                <Grid item xs={1}>
                  <SelectField
                    options={sex}
                    name="genderId"
                    label={<TransField i18nKey="gender" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <RadioGroup
                    name="isMemberOfLoyaltyProgram"
                    label={<TransField i18nKey="loyalty" />}
                    options={[
                      { value: 'true', label: 'Yes' },
                      { value: 'false', label: 'No' },
                    ]}
                    disabled
                    inline
                    defaultValue={
                      customerProfile?.isMemberOfLoyaltyProgram
                        ? 'true'
                        : 'false'
                    }
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" color="text.secondary" mb="8px">
                    <TransField i18nKey="profileCreated" />
                  </Typography>
                  {customerProfile
                    ? formatDate(customerProfile.createdOn.value)
                    : '-'}
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body2" color="text.secondary" mb="8px">
                    <TransField i18nKey="profileLastEdited" />
                  </Typography>
                  {customerProfile
                    ? formatDate(customerProfile.changedOn.value)
                    : '-'}
                </Grid>
                <Grid item xs={1}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    paragraph
                    mb="8px"
                  >
                    <TransField i18nKey="corporateClientAccount" />
                  </Typography>
                  {customerProfile && customerProfile.clientContract ? (
                    <ExternalLink
                      path={`/Contracts/ClientContract/Edit/${customerProfile.clientContract.id}`}
                      content={customerProfile.clientContract.name}
                    />
                  ) : (
                    ''
                  )}
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    options={seatPreferences}
                    name="seatPreferenceIds"
                    multiple
                    label={<TransField i18nKey="seatPreferences" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <SelectField
                    options={dietaryRequirements}
                    multiple
                    name="dietaryRequirementIds"
                    label={<TransField i18nKey="dietaryRequirements" />}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Typography
                variant="subtitle"
                fontWeight="700"
                paragraph
                sx={{ mt: '16px' }}
              >
                <TransSubtitle i18nKey="concessions" />
              </Typography>
              <Grid
                container
                sx={{ alignItems: 'baseline', mb: '16px' }}
                spacing={3}
                columns={5}
              >
                <Grid item xs={1}>
                  <SelectField
                    showEmptyOption
                    options={concessionTypes}
                    name="concessionDetails.concessionTypeId"
                    label={<TransField i18nKey="concessionType" />}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    name="concessionDetails.concessionId"
                    label={<TransField i18nKey="concessionId" />}
                  />
                </Grid>
              </Grid>
              <Stack
                direction="row"
                sx={{ alignItems: 'center', justifyContent: 'end' }}
                spacing={1}
              >
                {customerProfile?.id ? (
                  <Button
                    disabled={pristine}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetChanges" />
                  </Button>
                ) : (
                  <Button
                    disabled={true}
                    variant="text"
                    component={Link}
                    to="/organizations"
                  >
                    <TransButton i18nKey="cancel" />
                  </Button>
                )}
                <Button
                  disabled={submitting || pristine}
                  startIcon={<Icon name="check" />}
                  type="submit"
                  variant="contained"
                >
                  {customerProfile?.id ? (
                    <TransButton i18nKey="save" />
                  ) : (
                    <TransButton i18nKey="create" />
                  )}
                </Button>
              </Stack>
            </Loadable>
          </CardContent>
        </form>
      </FormProvider>
      {customerProfile && (
        <CustomerProfileDeleteModal
          onClose={() => setIsDeleteModalOpen(false)}
          isOpen={isDeleteModalOpen}
          customerProfile={customerProfile}
        />
      )}
    </>
  );
};
