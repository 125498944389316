import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { TransTableHead } from 'i18n/trans/table';
import { useDispatch, useSelector } from 'store/utils';
import { CustomerProfileTransactions } from 'dto/customerProfile';
import { ExternalLink, Loadable, Table, TableColumns } from '@fleet/shared';
import { Row, usePagination, useRowSelect, useTable } from 'react-table';
import { customerTransactionsSelector } from 'features/customerProfile/customerProfileSelectors';
import { getCustomerProfileTransactions } from 'features/customerProfile/customerProfileActions';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { customerTransactionsTableLoadingSelector } from 'features/loading/loadingSelectors';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';

interface CustomerProfileTransactionsProps {}

export const Transactions: FC<CustomerProfileTransactionsProps> = () => {
  const transactions = useSelector(customerTransactionsSelector);
  const data = useMemo(() => transactions?.items ?? [], [transactions]);
  const loading = useSelector(customerTransactionsTableLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerProfileTransactions());
  }, [dispatch]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (transactions) {
        const { limit = pageSize, offset } = transactions;
        return offset / limit;
      }
      return 0;
    },
    [transactions]
  );

  const columns = useMemo<TableColumns<CustomerProfileTransactions>>(
    () => [
      {
        id: 'type.name',
        accessor: ({ type }) => type?.name,
        Header: <TransTableHead i18nKey="productName" />,
      },
      {
        id: 'ticket.id',
        accessor: ({ ticket }) => ticket?.id,
        Header: <TransTableHead i18nKey="ticketNumber" />,
        Cell: ({ row }: { row: Row<CustomerProfileTransactions> }) => (
          <ExternalLink
            path={`/Trips/Product/View/${row.original.ticket.id}`}
            underline="none"
            content={row.original.ticket.name}
          />
        ),
      },
      {
        id: 'number',
        accessor: (transaction) => transaction.number,
        Header: <TransTableHead i18nKey="transactionNumber" />,
        Cell: ({ row }: { row: Row<CustomerProfileTransactions> }) => (
          <ExternalLink
            path={`/Trips/Transaction/Edit/${row.original.id}`}
            underline="none"
            content={row.original.number}
          />
        ),
      },
      {
        id: 'trip.departureTime.value',
        accessor: ({ trip }) => trip?.departureTime?.value,
        Header: <TransTableHead i18nKey="trip" />,
        Cell: ({ row }: { row: Row<CustomerProfileTransactions> }) => (
          <ExternalLink
            path={`/Trips/Trip/Edit/${row.original.trip?.id}`}
            underline="none"
            content={formatDate(
              row.original.trip?.departureTime?.value,
              currentDateTimeFormat
            )}
          />
        ),
      },
      {
        id: 'departure.stopName',
        accessor: ({ departure }) => departure?.stopName,
        Header: <TransTableHead i18nKey="departureStop" />,
      },
      {
        id: 'departure.time.value',
        accessor: ({ departure }) =>
          formatDate(departure?.time.value, currentDateTimeFormat),

        Header: <TransTableHead i18nKey="departureTime" />,
      },
      {
        id: 'destination.time.value',
        accessor: ({ destination }) =>
          formatDate(destination?.time.value, currentDateTimeFormat),

        Header: <TransTableHead i18nKey="arrivalTime" />,
      },
      {
        id: 'status.name',
        accessor: ({ status }) => status?.name,
        Header: <TransTableHead i18nKey="status" />,
      },
      {
        id: 'purchasedOn.value',
        accessor: ({ purchasedOn }) =>
          formatDate(purchasedOn.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="purchasedOn" />,
      },
      {
        id: 'loyaltyPoints',
        accessor: 'loyaltyPoints',
        Header: <TransTableHead i18nKey="loyaltyPoints" />,
      },
    ],
    []
  );
  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getCustomerProfileTransactions(paginationParams)),
    [dispatch]
  );

  const table = useTable(
    {
      data,
      columns,
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: transactions?.totalCount,
    },
    usePagination,
    useRowSelect
  );

  return (
    <Loadable loading={loading}>
      <Table table={table} />
    </Loadable>
  );
};
