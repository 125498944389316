import { createTrans } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransBody = createTrans({
  balance: <Trans i18nKey="body.balance" defaults="Balance" />,
  changesSaved: (
    <Trans i18nKey="body.changesSaved" defaults="All changes saved" />
  ),
  deleteConfirmation: (
    <Trans
      i18nKey="body.deleteConfirmation"
      defaults="Are you sure you want to delete user {{firstName}} {{lastName}}?<br/>You can't undo this"
    />
  ),
});
