import {
  Button,
  FormControl,
  FormProvider,
  formSubmit,
  PeriodField,
  TextField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { useCallback, useEffect, useMemo } from 'react';
import { TravelPassSearchFilter } from 'dto/customerProfile';
import {
  getTravelPasses,
  resetTravelPassSearchTable,
  setTravelPassFilter,
} from 'features/customerProfile/customerProfileActions';
import { useDispatch, useSelector } from 'store/utils';
import { useComponentWillUnmount } from 'hooks/useComponentWillUnmount';
import { currentBusinessEntityIdSelector } from 'features/common/commonSelectors';
import { travelPassFilterSelector } from 'features/customerProfile/customerProfileSelectors';

export const ConnectTravelPassSearchForm = () => {
  const dispatch = useDispatch();
  const businessEntityId = useSelector(currentBusinessEntityIdSelector);
  const filter = useSelector(travelPassFilterSelector);

  useComponentWillUnmount(() => {
    dispatch(setTravelPassFilter({}));
    dispatch(resetTravelPassSearchTable());
  });

  const onSubmit = useCallback(
    (
      values: TravelPassSearchFilter & {
        PurchasedFromTo?: { from: Date | null; to: Date | null };
        ValidityFromTo?: { from: Date | null; to: Date | null };
      }
    ) =>
      formSubmit(async () => {
        (document.activeElement as HTMLInputElement)?.blur?.();
        values.OrganizationId = businessEntityId;

        if (values.PurchasedFromTo) {
          values.purchasedFrom = values.PurchasedFromTo.from;
          values.purchasedTo = values.PurchasedFromTo.to;
        }

        if (values.ValidityFromTo) {
          values.validityFrom = values.ValidityFromTo.from;
          values.validityTo = values.ValidityFromTo.to;
        }

        await dispatch(getTravelPasses({ ...values, offset: 0 }));
      }),
    [businessEntityId, dispatch]
  );

  const initialValues = useMemo(() => filter, [filter]);

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setTravelPassFilter(initialValues));
    }
  }, [dispatch, filter, initialValues]);

  const { form, handleSubmit } = useForm<TravelPassSearchFilter>({
    initialValues,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setTravelPassFilter({}));
  }, [form, dispatch]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={3}>
          <Grid item xs={1}>
            <TextField
              name="TravelPassNumber"
              label={<TransField i18nKey="travelPassNumber" />}
            />
          </Grid>
          <PeriodField
            from={{
              name: 'PurchasedFromTo',
              label: <TransField i18nKey="purchasedFromTo" />,
              selectsRange: true,
              isClearable: true,
            }}
            to={{
              name: 'ValidityFromTo',
              label: <TransField i18nKey="validFromTo" />,
              selectsRange: true,
              isClearable: true,
            }}
          />
          <Grid item xs="auto" sx={{ ml: 'auto' }}>
            <Stack direction="row" spacing={2}>
              <FormControl label="&nbsp;">
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  variant="text"
                  onClick={handleReset}
                >
                  <TransButton i18nKey="resetFields" />
                </Button>
              </FormControl>
              <FormControl label="&nbsp;">
                <Button variant="contained" type="submit" icon="search">
                  <TransButton i18nKey="search" />
                </Button>
              </FormControl>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
