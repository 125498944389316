import { createSelector as createSelectorNative } from '@reduxjs/toolkit';
import { createSelector, selector } from 'store/utils';
import { businessEntitiesSelector } from 'features/classification/classificationSelectors';

export const selectCurrentBusinessEntityId = selector(
  (state) => state.common.currentBusinessEntityId
);

export const currentBusinessEntityIdSelector = createSelector(
  selectCurrentBusinessEntityId
);

export const currentBusinessEntityNameSelector = createSelectorNative(
  [businessEntitiesSelector, selectCurrentBusinessEntityId],
  (businessEntities, currentBusinessEntityId) =>
    businessEntities?.find((entity) => entity.id === currentBusinessEntityId)
      ?.name
);
