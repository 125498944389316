import {
  Checkbox,
  ExternalLink,
  Loadable,
  Table,
  TableColumns,
  useIndeterminateRowSelectCheckbox,
} from '@fleet/shared';
import { CustomerProfileVouchers } from 'dto/customerProfile';
import { useDispatch, useSelector } from 'store/utils';
import {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  deleteCustomerProfileVouchers,
  getCustomerProfileVoucherBalance,
  getCustomerProfileVouchers,
} from 'features/customerProfile/customerProfileActions';
import {
  Row,
  useFilters,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import {
  customerProfileVoucherBalanceSelector,
  customerProfileVoucherFilterSelector,
  customerProfileVouchersSelector,
} from 'features/customerProfile/customerProfileSelectors';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { customerVouchersTableLoadingSelector } from 'features/loading/loadingSelectors';
import { Box, Button, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { ConnectVouchersModal } from 'routes/customerProfile/ConnectVouchersModal';
import { TransBody } from 'i18n/trans/body';

export const Vouchers: FunctionComponent = () => {
  const [isConnectVouchersModalOpen, setIsConnectVouchersModalOpen] =
    useState(false);
  const vouchers = useSelector(customerProfileVouchersSelector);
  const voucherBalance = useSelector(customerProfileVoucherBalanceSelector);
  const filter = useSelector(customerProfileVoucherFilterSelector);
  const loading = useSelector(customerVouchersTableLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerProfileVouchers());
    dispatch(getCustomerProfileVoucherBalance());
  }, [dispatch]);

  const getPage = useCallback(
    (pageSize: number) => {
      if (vouchers) {
        const { limit = pageSize, offset } = vouchers;
        return offset / limit;
      }
      return 0;
    },
    [vouchers]
  );
  const data = useMemo(() => vouchers?.items ?? [], [vouchers]);
  const columns = useMemo<TableColumns<CustomerProfileVouchers>>(
    () => [
      {
        id: 'code',
        accessor: 'code',
        Header: <TransTableHead i18nKey="voucherCode" />,
        Cell: ({ row }: { row: Row<CustomerProfileVouchers> }) => (
          <ExternalLink
            path={`/Trips/PrepaidVoucher/Edit/${row.original.id}`}
            underline="none"
            content={row.original.code}
          />
        ),
      },
      {
        id: 'validUntil.value',
        accessor: ({ validUntil }) =>
          formatDate(validUntil?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="validUntil" />,
      },
      {
        accessor: 'nominalValue',
        Header: <TransTableHead i18nKey="nominalValue" />,
      },
      {
        accessor: 'usedAmount',
        Header: <TransTableHead i18nKey="usedAmount" />,
      },
      {
        id: 'numberOfUsedPayments',
        accessor: ({ numberOfUsedPayments, maxNumberOfPayments }) =>
          `${numberOfUsedPayments} / ${maxNumberOfPayments ?? '-'}`,
        Header: <TransTableHead i18nKey="usedPaymentsMax" />,
      },
      {
        id: 'numberOfUsedTickets',
        accessor: ({ numberOfUsedTickets, maxNumberOfTickets }) =>
          `${numberOfUsedTickets}/ ${maxNumberOfTickets ?? '-'}`,
        Header: <TransTableHead i18nKey="usedTicketsMax" />,
      },
      {
        accessor: 'createdBy',
        Header: <TransTableHead i18nKey="createdBy" />,
      },
      {
        id: 'cancelledOn.value',
        accessor: ({ cancelledOn }) =>
          formatDate(cancelledOn?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="cancelled" />,
      },
      {
        id: 'voucherType',
        accessor: ({ voucherType }) => voucherType?.name,
        Header: <TransTableHead i18nKey="voucherType" />,
      },
    ],
    []
  );

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(
        getCustomerProfileVouchers({
          ...filter,
          ...paginationParams,
        })
      ),
    [dispatch, filter]
  );

  const table = useTable(
    {
      data,
      columns,
      initialState: {
        pageSize: 10,
      },
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: vouchers?.totalCount,
    },
    useFilters,
    useIndeterminateRowSelectCheckbox,
    usePagination,
    useRowSelect
  );

  const onRowsRemove = useCallback(async () => {
    await dispatch(
      deleteCustomerProfileVouchers(
        table.selectedFlatRows.map((row) => row.original.id)
      )
    );
    await dispatch(getCustomerProfileVouchers());
  }, [dispatch, table.selectedFlatRows]);

  const handleIsActiveFilterToggle = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      await dispatch(
        getCustomerProfileVouchers(
          e.target.checked ? { isActive: e.target.checked } : {}
        )
      );
    },
    [dispatch]
  );

  return (
    <>
      <Loadable loading={loading}>
        <Box sx={{ mb: 6 }}>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="subtitle"
              color="text.primary"
              component="span"
            >
              <TransBody i18nKey="balance" />
            </Typography>
            <span>: {voucherBalance} EUR</span>
          </Box>
          <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
            <div>
              <Checkbox
                onChange={handleIsActiveFilterToggle}
                label={
                  <Typography
                    variant="body2"
                    color="text.primary"
                    component="span"
                  >
                    <TransField i18nKey="showValidVouchers" />
                  </Typography>
                }
                size="small"
              />
            </div>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ ml: 'auto' }}
            >
              <Button
                disabled={!table.selectedFlatRows.length}
                variant="text"
                onClick={onRowsRemove}
                startIcon={<Icon name="delete" />}
                color="error"
              >
                <TransButton i18nKey="removeVouchers" />
              </Button>
              <Button
                variant="text"
                onClick={() => setIsConnectVouchersModalOpen(true)}
                startIcon={<Icon name="plus" />}
              >
                <TransButton i18nKey="connectVouchers" />
              </Button>
            </Stack>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </Loadable>
      <ConnectVouchersModal
        isOpen={isConnectVouchersModalOpen}
        onClose={() => setIsConnectVouchersModalOpen(false)}
      />
    </>
  );
};
