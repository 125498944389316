import { createTrans, TransKeys } from 'i18n/trans';
import { Trans } from 'react-i18next';

export const TransSubtitle = createTrans({
  assigningLoyaltyPoints: (
    <Trans
      i18nKey="subtitle.assigningLoyaltyPoints"
      defaults="Assign loyalty points"
    />
  ),
  businessCustomerQty: (
    <Trans
      i18nKey="subtitle.businessCustomerQty"
      defaults="{{num}} business customers"
    />
  ),
  businessCustomerSetup: (
    <Trans
      i18nKey="subtitle.businessCustomerSetup"
      defaults="Business customer setup"
    />
  ),
  concessions: <Trans i18nKey="subtitle.concessions" defaults="Concessions" />,
  contractRuleSubtitle: (
    <Trans
      i18nKey="subtitle.contractRuleSubtitle"
      defaults="Rules and settings for business customer contract"
    />
  ),
  conversionRates: (
    <Trans i18nKey="subtitle.conversionRates" defaults="Conversion rates" />
  ),
  creditLimitSetup: (
    <Trans i18nKey="subtitle.creditLimitSetup" defaults="Credit limit setup" />
  ),
  customerProfilesQty: (
    <Trans
      i18nKey="subtitle.customerProfilesQty"
      defaults="{{num}} customer profiles"
    />
  ),
  details: <Trans i18nKey="subtitle.details" defaults="Details" />,
  editBusinessCustomerContact: (
    <Trans
      i18nKey="subtitle.editBusinessCustomerContact"
      defaults="Edit business customer contact"
    />
  ),
  editBusinessCustomerUnit: (
    <Trans
      i18nKey="subtitle.editBusinessCustomerUnit"
      defaults="Edit business customer unit"
    />
  ),
  editEmployeeAccount: (
    <Trans
      i18nKey="subtitle.editEmployeeAccount"
      defaults="Edit employee account"
    />
  ),
  informationLocalization: (
    <Trans
      i18nKey="subtitle.informationLocalization"
      defaults="Information localization"
    />
  ),
  latestCard: <Trans i18nKey="subtitle.latestCard" defaults="Latest card" />,
  linkCustomerProfile: (
    <Trans
      i18nKey="subtitle.linkCustomerProfile"
      defaults="Link customer profile *"
    />
  ),
  linkedCustomerProfile: (
    <Trans
      i18nKey="subtitle.linkedCustomerProfile"
      defaults="Linked customer profile"
    />
  ),
  loyaltyDetails: (
    <Trans i18nKey="subtitle.loyaltyDetails" defaults="Loyalty details" />
  ),
  newBusinessCustomerContact: (
    <Trans
      i18nKey="subtitle.newBusinessCustomerContact"
      defaults="New business customer contact"
    />
  ),
  newBusinessCustomerUnit: (
    <Trans
      i18nKey="subtitle.newBusinessCustomerUnit"
      defaults="New business customer unit"
    />
  ),
  newEmployeeAccount: (
    <Trans
      i18nKey="subtitle.newEmployeeAccount"
      defaults="New employee account"
    />
  ),
  noCardAdded: (
    <Trans i18nKey="subtitle.noCardAdded" defaults="No card added." />
  ),
  pointsImport: (
    <Trans i18nKey="subtitle.pointsImport" defaults="Points import" />
  ),
  search: <Trans i18nKey="subtitle.search" defaults="Search" />,
  searchResults: (
    <Trans i18nKey="subtitle.searchResults" defaults="Search results" />
  ),
  specialDiscounts: (
    <Trans i18nKey="subtitle.specialDiscounts" defaults="Special discounts" />
  ),
  systemParameters: (
    <Trans i18nKey="subtitle.systemParamaters" defaults="System parameters" />
  ),
  targetGroups: (
    <Trans i18nKey="subtitle.targetGroups" defaults="Target groups" />
  ),
  tiers: <Trans i18nKey="subtitle.tiers" defaults="Tiers" />,
  travelPassDiscounts: (
    <Trans
      i18nKey="subtitle.travelPassDiscounts"
      defaults="Travel pass discounts"
    />
  ),
});

export type TransSubtitleKeys = TransKeys<typeof TransSubtitle>;
