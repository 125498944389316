import type { FC } from 'react';
import { useCallback, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormProvider,
  makeClassificationOptions,
  PeriodField,
  SearchForm,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Grid, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import {
  employeeAccountsLightSelector,
  transactionFiltersSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getEmployeeAccountsLight,
  getTransactions,
  setBusinessCustomerTransactionFilter,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { TransactionFilter } from 'dto/businessCustomerContracts';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';

interface TransactionsSearchFormProps {}

export const TransactionsSearchForm: FC<TransactionsSearchFormProps> = () => {
  const filter = useSelector(transactionFiltersSelector);
  const transactionStatuses = useClassificationOptions(
    ClassificationGroup.TRANSACTION_STATUS
  );
  const dispatch = useDispatch();
  const employeeAccounts = useSelector(employeeAccountsLightSelector);
  const employeeAccountsOptions = makeClassificationOptions(
    employeeAccounts ?? []
  );

  useEffect(() => {
    dispatch(getEmployeeAccountsLight());
  }, [dispatch]);

  const onSubmit = useCallback(
    (formValues: TransactionFilter) => {
      (document.activeElement as HTMLInputElement)?.blur?.();
      dispatch(getTransactions({ ...formValues, offset: 0 }));
    },
    [dispatch]
  );

  const { form, handleSubmit } = useForm({
    initialValues: filter,
    onSubmit,
  });

  const handleReset = useCallback(() => {
    form.reset();
    dispatch(setBusinessCustomerTransactionFilter({}));
  }, [form, dispatch]);

  return (
    <SearchForm title={<TransSubtitle i18nKey="search" />}>
      <FormProvider form={form}>
        <form onSubmit={handleSubmit}>
          <Grid container sx={{ alignItems: 'center' }} spacing={2} columns={4}>
            <PeriodField
              from={{
                name: 'departureTimeFrom',
                label: <TransField i18nKey="departureTimeFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'departureTimeTo',
                label: <TransField i18nKey="departureTimeTo" />,
                isClearable: true,
              }}
            />
            <PeriodField
              from={{
                name: 'transactionTimeFrom',
                label: <TransField i18nKey="transactionTimeFrom" />,
                isClearable: true,
              }}
              to={{
                name: 'transactionTimeTo',
                label: <TransField i18nKey="transactionTimeTo" />,
                isClearable: true,
              }}
            />
            <Grid item xs={1}>
              <SelectField
                options={transactionStatuses}
                name="statusId"
                label={<TransField i18nKey="status" />}
              />
            </Grid>
            <Grid item xs={1}>
              <SelectField
                options={employeeAccountsOptions}
                name="employeeAccountId"
                label={<TransField i18nKey="employeeAccountName" />}
              />
            </Grid>
            <Grid item xs="auto" sx={{ ml: 'auto' }}>
              <Stack direction="row" spacing={2}>
                <FormControl label="&nbsp;">
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    variant="text"
                    onClick={handleReset}
                  >
                    <TransButton i18nKey="resetFields" />
                  </Button>
                </FormControl>
                <FormControl label="&nbsp;">
                  <Button variant="contained" type="submit" icon="search">
                    <TransButton i18nKey="search" />
                  </Button>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </SearchForm>
  );
};
