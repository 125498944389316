import { FC, useEffect } from 'react';
import { Layout } from '@fleet/shared';
import { Divider } from '@mui/material';
import { BusinessCustomerTable } from './BusinessCustomerTable';
import { BusinessCustomerSearch } from './BusinessCustomerSearch';
import { useTranslation } from 'react-i18next';

export const BusinessCustomer: FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = t('title.businessCustomer', 'Business customer');
  }, [t]);

  return (
    <Layout>
      <>
        <BusinessCustomerSearch />
        <Divider />
        <BusinessCustomerTable />
      </>
    </Layout>
  );
};
