import { useSelector } from 'store/utils';
import { countriesSelector } from 'features/classification/classificationSelectors';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';

export const useAreaCodes = () =>
  _sortBy(
    _uniqBy(
      useSelector(countriesSelector).map(({ phoneAreaCode }) => ({
        value: phoneAreaCode,
        label: phoneAreaCode,
      })),
      'label'
    ),
    'label'
  );
