import { FC, useCallback } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import {
  Button,
  FormProvider,
  formSubmit,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { TransField } from 'i18n/trans/field';
import { CustomerProfileLoyaltyPoints } from 'dto/customerProfile';
import { useDispatch, useSelector } from 'store/utils';
import {
  createCustomerProfilePoints,
  getCustomerProfileLoyaltySummary,
} from 'features/customerProfile/customerProfileActions';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { listOfReasons, listOfTimeMetrics } from 'consts';
import {
  currentCustomerProfileSelector,
  customerLoyaltyTargetGroupSelector,
} from 'features/customerProfile/customerProfileSelectors';
import { MultiChip } from 'components/MultiChip';

const useStyles = makeStyles(() => ({
  label: {
    color: '#757B86',
  },
}));

interface CustomerProfileLoyaltyProps {}

export const Loyalty: FC<CustomerProfileLoyaltyProps> = () => {
  const targetGroups = useSelector(customerLoyaltyTargetGroupSelector);
  const current = useSelector(currentCustomerProfileSelector);
  const dispatch = useDispatch();
  const classes = useStyles();

  const onSubmit = useCallback(
    (data: CustomerProfileLoyaltyPoints) =>
      formSubmit(async () => {
        dispatch(createCustomerProfilePoints(data))
          .unwrap()
          .then(() => {
            dispatch(getCustomerProfileLoyaltySummary());
          });
      }),
    [dispatch]
  );

  const { form, handleSubmit, submitting } =
    useForm<CustomerProfileLoyaltyPoints>({
      onSubmit,
      subscription: { submitting: true },
    });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider form={form}>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
          form.restart({});
        }}
      >
        <Grid container sx={{ alignItems: 'baseline' }} spacing={2} columns={5}>
          <Grid item xs={1}>
            <Typography
              className={classes.label}
              variant="body2"
              fontWeight="400"
              paragraph
            >
              <TransTitle i18nKey="loyaltyId" />
            </Typography>
            <Typography variant="body1" fontWeight="400" paragraph>
              {current?.loyalty.accountCode}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              className={classes.label}
              variant="body2"
              fontWeight="400"
              paragraph
            >
              <TransTitle i18nKey="tier" />
            </Typography>
            <Typography variant="body1" fontWeight="400" paragraph>
              {current?.loyalty.tier?.name}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              className={classes.label}
              variant="body2"
              fontWeight="400"
              paragraph
            >
              <TransTitle i18nKey="totalPoints" />
            </Typography>
            <Typography variant="body1" fontWeight="400" paragraph>
              {current?.loyalty.totalPoints}
            </Typography>
          </Grid>

          <Grid item xs={1}>
            <Typography
              className={classes.label}
              variant="body2"
              fontWeight="400"
              paragraph
            >
              <TransTitle i18nKey="unusedPoints" />
            </Typography>
            <Typography variant="body1" fontWeight="400" paragraph>
              {current?.loyalty?.activePoints}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              className={classes.label}
              variant="body2"
              fontWeight="400"
              paragraph
            >
              <TransTitle i18nKey="loyaltyTargetGroups" />
            </Typography>
            <MultiChip items={targetGroups?.map((group) => group.name)} />
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: '24px' }} />
        <Typography
          variant="subtitle"
          fontWeight="700"
          sx={{ marginTop: '24px' }}
          paragraph
        >
          <TransSubtitle i18nKey="assigningLoyaltyPoints" />
        </Typography>
        <Grid
          container
          sx={{
            alignItems: 'flex-start',
            pb: 2,
          }}
          spacing={3}
          columns={5}
        >
          <Grid item xs={1}>
            <TextField
              name="points"
              label={<TransField i18nKey="points" />}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={1} alignItems="flex-end">
            <Grid container alignItems="flex-start" spacing={1} columns={4}>
              <Grid item xs={2}>
                <TextField
                  name="validForMultiplier"
                  label={<TransField i18nKey="validForMultiplier" />}
                  type="number"
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  name="validForType"
                  label={<TransField i18nKey="validForType" />}
                  options={listOfTimeMetrics}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <SelectField
              name="pointSourceId"
              showEmptyOption
              label={<TransField i18nKey="reason" />}
              options={listOfReasons}
              required
            />
          </Grid>
          <Grid item xs={2} sx={{ display: 'flex' }}>
            <Stack
              direction="row"
              sx={{ ml: 'auto', mt: '25px', alignItems: 'center' }}
              spacing={1}
            >
              <Button onClick={handleReset} variant="text">
                <TransButton i18nKey="resetFields" />
              </Button>
              <Button
                icon="check"
                type="submit"
                sx={{ ml: 2 }}
                disabled={submitting}
              >
                <TransButton i18nKey="confirm" />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};
