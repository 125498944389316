import { useCallback, useMemo } from 'react';
import { useFilters, usePagination, useRowSelect } from 'react-table';
import {
  ExternalLink,
  FormProvider,
  Loadable,
  SearchResult,
  Table,
  TableColumns,
  useForm,
  useFormTable,
} from '@fleet/shared';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { PaginationParams } from '@fleet/shared/dto/pagination';
import { TransactionsSearchForm } from './TransactionsSearchForm';
import { Divider } from '@mui/material';
import { useDispatch, useSelector } from 'store/utils';
import { Transaction } from 'dto/businessCustomerContracts';
import { TransTableHead } from 'i18n/trans/table';
import {
  transactionFiltersSelector,
  transactionsSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { businessCustomerContractTransactionsTableLoadingSelector } from 'features/loading/loadingSelectors';
import { getTransactions } from 'features/businessCustomerContracts/businessCustomerContractsActions';

export const TransactionsTable = () => {
  const transactions = useSelector(transactionsSelector);
  const loading = useSelector(
    businessCustomerContractTransactionsTableLoadingSelector
  );
  const filter = useSelector(transactionFiltersSelector);
  const dispatch = useDispatch();
  const getPage = useCallback(
    (pageSize: number) => {
      if (transactions) {
        const { limit = pageSize, offset } = transactions;
        return offset / limit;
      }
      return 0;
    },
    [transactions]
  );
  const data = useMemo(() => transactions?.items ?? [], [transactions]);

  const columns = useMemo<TableColumns<Transaction>>(
    () => [
      {
        accessor: 'name',
        Header: <TransTableHead i18nKey="name" />,
        Cell: ({ row, value }) => {
          return (
            <ExternalLink
              content={value}
              underline="none"
              path={`/Trips/Transaction/Edit/${row.original.id}`}
            />
          );
        },
      },
      {
        id: 'employeeAccount.id',
        accessor: ({ employeeAccount }) => employeeAccount?.name,
        Header: <TransTableHead i18nKey="employeeAccountName" />,
      },
      {
        accessor: 'ticketNumber',
        Header: <TransTableHead i18nKey="ticketNumber" />,
      },
      {
        id: 'carrier.id',
        accessor: ({ carrier }) => carrier?.name,
        Header: <TransTableHead i18nKey="carrier" />,
      },
      {
        id: 'departureTime.value',
        accessor: ({ departureTime }) =>
          formatDate(departureTime?.value, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="departureTime" />,
      },
      {
        id: 'departureStop.id',
        accessor: ({ departureStop }) => departureStop?.name,
        Header: <TransTableHead i18nKey="departureStop" />,
      },
      {
        id: 'destinationStop.id',
        accessor: ({ destinationStop }) => destinationStop?.name,
        Header: <TransTableHead i18nKey="destinationStop" />,
      },
      {
        accessor: 'price',
        Header: <TransTableHead i18nKey="price" />,
      },
      {
        id: 'status',
        accessor: ({ status }) => status?.name,
        Header: <TransTableHead i18nKey="status" />,
      },
    ],
    []
  );

  const { form } = useForm({
    initialValues: {
      rows: data,
    },
  });

  const handlePageChange = useCallback(
    async (paginationParams: PaginationParams) =>
      await dispatch(getTransactions({ ...filter, ...paginationParams })),
    [dispatch, filter]
  );

  const table = useFormTable(
    {
      form,
      data,
      columns,
      initialState: {
        pageSize: 10,
      },
      pageCount: -1,
      useControlledState: (state) => ({
        ...state,
        pageIndex: getPage(state.pageSize),
      }),
      manualPagination: true,
      onPageChange: handlePageChange,
      total: transactions?.totalCount,
    },
    useFilters,
    usePagination,
    useRowSelect
  );

  return (
    <>
      <TransactionsSearchForm />
      <Divider sx={{ mb: 3 }} />
      <Loadable loading={loading}>
        <SearchResult results={data.length} loading={loading}>
          <FormProvider form={form}>
            <Table
              getTableProps={{
                sx: {
                  tableLayout: 'fixed',
                },
              }}
              table={table}
            />
          </FormProvider>
        </SearchResult>
      </Loadable>
    </>
  );
};
