import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  FormProvider,
  Loadable,
  makeClassificationOptions,
  Table,
  TableColumns,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useRowEditActions,
} from '@fleet/shared';
import { useFilters, useRowSelect } from 'react-table';
import { TransTableHead } from 'i18n/trans/table';
import { Box, Button, Divider, Stack } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { useDispatch, useSelector } from 'store/utils';
import { DistanceConversionRate } from 'dto/loyaltyProgram';
import {
  deleteDistanceConversionRates,
  getActiveLines,
  getDistanceConversionRateExceptions,
  getDistanceConversionRates,
  getPlaceClasses,
  updateOrCreateDistanceConversionRate,
} from 'features/loyaltyProgram/loyaltyProgramActions';
import {
  distanceConversionRatesSelector,
  placeClassesSelector,
} from 'features/loyaltyProgram/loyaltyProgramSelectors';
import { DistanceConversionRateExceptions } from 'routes/loyaltyProgram/tabs/DistanceConversionRates/DistanceConversionRateExceptions';
import { transactionTypeOptions } from 'consts';
import { distanceConversionRatesLoadingSelector } from 'features/loading/loadingSelectors';
import { formatDate } from '@fleet/shared/utils/date';

interface ConversionRatesProps {}

export const DistanceConversionRates: FC<ConversionRatesProps> = () => {
  const dispatch = useDispatch();
  const placeClasses = useSelector(placeClassesSelector);
  const data = useSelector(distanceConversionRatesSelector);
  const loading = useSelector(distanceConversionRatesLoadingSelector);

  useEffect(() => {
    dispatch(getPlaceClasses());
    dispatch(getDistanceConversionRates());
    dispatch(getDistanceConversionRateExceptions());
    dispatch(getActiveLines());
  }, [dispatch]);

  const columns: TableColumns<DistanceConversionRate> = useMemo(
    () => [
      {
        accessor: 'distanceToPointsRate',
        Header: <TransTableHead i18nKey="distanceRate" />,
      },
      {
        accessor: 'distanceToCarbonDioxideGramsRate',
        Header: <TransTableHead i18nKey="carbonRate" />,
        editableProps: { required: false },
      },
      {
        id: 'validity.from',
        accessor: ({ validity }) => formatDate(validity?.from),
        Header: <TransTableHead i18nKey="validFrom" />,
        type: 'date',
        editableProps: { withPortal: true },
      },
      {
        id: 'validity.to',
        accessor: ({ validity }) => formatDate(validity?.to),
        Header: <TransTableHead i18nKey="validTo" />,
        type: 'date',
        editableProps: { withPortal: true },
      },
      {
        id: 'placeClass.id',
        accessor: ({ placeClass }) => placeClass?.id,
        Header: <TransTableHead i18nKey="placeClasses" />,
        type: 'select',
        editableProps: {
          options: makeClassificationOptions(placeClasses),
          showEmptyOption: true,
          required: false,
        },
      },
      {
        id: 'transactionType.id',
        accessor: ({ transactionType }) => transactionType?.id,
        Header: <TransTableHead i18nKey="transactionType" />,
        type: 'select',
        editableProps: { options: transactionTypeOptions },
      },
    ],
    [placeClasses]
  );
  const { form } = useForm<{ rows: Array<DistanceConversionRate> }>({
    initialValues: {
      rows: data,
    },
  });
  const table = useFormTable(
    {
      data,
      columns,
      form,
      onRowUpdate: async (payload) => {
        const { placeClass, validity, transactionType, ...rest } = payload;

        await dispatch(
          updateOrCreateDistanceConversionRate({
            ...rest,
            transactionTypeId: transactionType.id,
            placeClassId: placeClass?.id,
            validity: {
              ...validity,
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          })
        );
        await dispatch(getDistanceConversionRates());
      },
    },
    useFilters,
    useRowSelect,
    useIndeterminateRowSelectCheckbox,
    useRowEditActions
  );

  const onRowsRemove = useCallback(
    async (payload: Array<DistanceConversionRate>) => {
      await dispatch(deleteDistanceConversionRates(payload));
      await dispatch(getDistanceConversionRates());
    },
    [dispatch]
  );

  const { addRow, removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: onRowsRemove,
  });

  return (
    <Loadable loading={loading}>
      <FormProvider form={form}>
        <Box sx={{ mb: 6 }}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 1 }}
          >
            <Button
              disabled={!table.selectedFlatRows.length}
              variant="text"
              onClick={removeSelectedRows}
              startIcon={<Icon name="delete" />}
              color="error"
            >
              <TransButton i18nKey="deleteSelected" />
            </Button>
            <Button
              variant="text"
              onClick={addRow}
              startIcon={<Icon name="plus" />}
            >
              <TransButton i18nKey="addNew" />
            </Button>
          </Stack>
          <Table
            getTableProps={{
              sx: {
                tableLayout: 'fixed',
              },
            }}
            table={table}
          />
        </Box>
      </FormProvider>
      <Divider />
      <DistanceConversionRateExceptions />
    </Loadable>
  );
};
