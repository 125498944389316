import { type FC, useEffect } from 'react';
import { Layout } from '@fleet/shared';
import { Divider } from '@mui/material';
import { BusinessCustomerContractsSearch } from './BusinessCustomerContractsSearch';
import { BusinessCustomerContractsTable } from './BusinessCustomerContractsTable';
import { useTranslation } from 'react-i18next';

export const BusinessCustomerContracts: FC = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t(
      'title.businessCustomerContracts',
      'Business customer contracts'
    );
  }, [t]);

  return (
    <Layout>
      <>
        <BusinessCustomerContractsSearch />
        <Divider />
        <BusinessCustomerContractsTable />
      </>
    </Layout>
  );
};
