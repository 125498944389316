import {
  CheckboxField,
  FormProvider,
  Loadable,
  makeClassificationOptions,
  SelectField,
  TextField,
  useForm,
} from '@fleet/shared';
import {
  Button,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'store/utils';
import { businessCustomerContractTabsLoadingSelector } from 'features/loading/loadingSelectors';
import {
  businessCustomerContractRulesSelector,
  currentBusinessCustomerContractSelector,
  businessCustomerOrganizationServicesSelector,
} from 'features/businessCustomerContracts/businessCustomerContractsSelectors';
import { useClassificationOptions } from 'hooks/useClassificationOptions';
import { ClassificationGroup } from 'dto/classification';
import {
  getBusinessCustomerContractRules,
  getOrganizationServices,
  updateBusinessCustomerContractRules,
} from 'features/businessCustomerContracts/businessCustomerContractsActions';
import { BusinessCustomerContractRulesRequest } from 'dto/businessCustomerContracts';
import { TransAlert } from 'i18n/trans/alert';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransField } from 'i18n/trans/field';
import { TransButton } from 'i18n/trans/button';
import { renderToString } from 'react-dom/server';

export const RulesAndSettings = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const loading = useSelector(businessCustomerContractTabsLoadingSelector);
  const contract = useSelector(currentBusinessCustomerContractSelector);
  const rules = useSelector(businessCustomerContractRulesSelector);
  const passengerCategories = useClassificationOptions(
    ClassificationGroup.BONUS_SCHEME_GROUP
  );
  const forLocalization = useClassificationOptions(
    ClassificationGroup.FOR_LOCALIZATION
  );
  const comfortLevels = useClassificationOptions(
    ClassificationGroup.COMFORT_LEVEL
  );
  const organizationServices = useSelector(
    businessCustomerOrganizationServicesSelector
  );
  const allowedPassengerCategories = [
    ...passengerCategories,
    forLocalization[0],
  ];
  const restrictedAddons = useMemo(
    () => makeClassificationOptions(organizationServices ?? []),
    [organizationServices]
  );

  useEffect(() => {
    dispatch(getBusinessCustomerContractRules());
  }, [dispatch]);

  useEffect(() => {
    if (contract?.contractor.id) {
      dispatch(getOrganizationServices(contract?.contractor.id));
    }
  }, [contract?.contractor.id, dispatch]);

  const onSubmit = useCallback(
    (values: BusinessCustomerContractRulesRequest) => {
      dispatch(updateBusinessCustomerContractRules(values))
        .unwrap()
        .then(() => {
          alert.success(
            <TransAlert i18nKey="businessCustomerContractRulesUpdated" />
          );
        })
        .finally(() => {
          dispatch(getBusinessCustomerContractRules());
        });
    },
    [alert, dispatch]
  );

  const initialValues: BusinessCustomerContractRulesRequest = useMemo(() => {
    if (!rules) {
      return {};
    }

    const { restrictedAddOns, ...rest } = rules;
    return {
      ...rest,
      allowedPassengerTypes: rest?.allowedPassengerTypes.map((type) => type.id),
      restrictedAddOnIds: restrictedAddOns?.map((addon) => addon.id),
      allowedComfortLevels: rest?.allowedComfortLevels.map(
        (comfortLevel) => comfortLevel.id
      ),
    };
  }, [rules]);

  const { form, handleSubmit, submitting, pristine, values } =
    useForm<BusinessCustomerContractRulesRequest>({
      initialValues,
      onSubmit,
      subscription: { submitting: true, pristine: true, values: true },
    });

  const handleReset = useCallback(() => {
    form.reset();
  }, [form]);

  return (
    <FormProvider form={form}>
      <form onSubmit={handleSubmit}>
        <CardContent sx={{ p: '16px 24px' }}>
          <Loadable loading={loading}>
            <Typography variant="subtitle" fontWeight="700" paragraph mb="24px">
              <TransSubtitle i18nKey="contractRuleSubtitle" />
            </Typography>
            <Grid
              container
              sx={{ alignItems: 'baseline', mb: '16px' }}
              spacing={2}
              columns={5}
              direction="column"
            >
              <Grid item xs={1}>
                <CheckboxField
                  name="isInternationalJourneyAllowed"
                  label={<TransField i18nKey="internationalJourneyRule" />}
                />
              </Grid>
              <Grid item xs={1}>
                <CheckboxField
                  name="isGroupTicketAllowed"
                  label={<TransField i18nKey="groupTicketAllowedRule" />}
                />
              </Grid>
              <Grid item xs={1}>
                <CheckboxField
                  name="areGiftCardsAllowed"
                  label={<TransField i18nKey="giftCardsAllowedRule" />}
                />
              </Grid>
              <Grid item xs={1}>
                <CheckboxField
                  name="areAddOnsAllowed"
                  label={<TransField i18nKey="addOnsAllowedRule" />}
                />
              </Grid>
              {values.areAddOnsAllowed && (
                <Grid item xs={1} sx={{ width: 250 }}>
                  <SelectField
                    options={restrictedAddons}
                    multiple
                    name="restrictedAddOnIds"
                    label={<TransField i18nKey="restrictedAddons" />}
                  />
                </Grid>
              )}
            </Grid>
            <Divider />
            <Grid
              container
              sx={{ alignItems: 'baseline', mb: '16px', mt: '8px' }}
              spacing={2}
              columns={5}
              direction="column"
            >
              <Grid item xs={1} sx={{ width: 250 }}>
                <TextField
                  name="maxNumberOfTicketsInBooking"
                  label={<TransField i18nKey="maxNumberOfTicketsInBooking" />}
                />
              </Grid>
              <Grid item xs={1} sx={{ width: 250 }}>
                <SelectField
                  options={allowedPassengerCategories.reverse()}
                  multiple
                  name="allowedPassengerTypes"
                  label={<TransField i18nKey="allowedPassengerTypes" />}
                />
              </Grid>
              <Grid item xs={1} sx={{ width: 250 }}>
                <SelectField
                  options={comfortLevels}
                  multiple
                  {...(!values.allowedComfortLevels?.length && {
                    placeholder: renderToString(<TransField i18nKey="all" />),
                  })}
                  name="allowedComfortLevels"
                  label={<TransField i18nKey="allowedComfortLevels" />}
                />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              sx={{ alignItems: 'center', justifyContent: 'end' }}
              spacing={1}
            >
              <Button disabled={pristine} variant="text" onClick={handleReset}>
                <TransButton i18nKey="resetChanges" />
              </Button>
              <Button
                disabled={submitting || pristine}
                startIcon={<Icon name="check" />}
                type="submit"
                variant="contained"
              >
                <TransButton i18nKey="save" />
              </Button>
            </Stack>
          </Loadable>
        </CardContent>
      </form>
    </FormProvider>
  );
};
